import * as React from 'react'

export const withLazySuspense = <T extends object>(
  load: () => Promise<{ default: React.ComponentType<T> }>,
  fallback: React.ReactNode = null,
) => {
  const Lazy = React.lazy(load) as unknown as React.ComponentType<T>
  const WithLazySuspense = (props: T) => {
    return (
      <React.Suspense fallback={fallback}>
        <Lazy {...props} />
      </React.Suspense>
    )
  }

  WithLazySuspense.displayName = `WithLazySuspense${Lazy.displayName}`

  return WithLazySuspense
}
