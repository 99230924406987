import { RouteObject, redirect } from 'react-router-dom'

import CommonLayout from './components/layout/Common/CommonLayout'
import { AuthGuard } from './lib/auth'
import { withLazySuspense } from './lib/withLazySuspense'
import Login from './modules/auth/Login'

const KanbanBoard = withLazySuspense(() => import('@/modules/kanban-board'))
const Tickets = withLazySuspense(() => import('@/modules/tickets'))
const UserManagement = withLazySuspense(() => import('@/modules/user-management'))
const ClientManagement = withLazySuspense(() => import('@/modules/client-management'))
const Dashboard = withLazySuspense(() => import('@/modules/dashboard'))
const NotFound = withLazySuspense(() => import('@/modules/not-found'))

function redirectToBoard() {
  return redirect('/kanban-board')
}

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    element: <AuthGuard />,
    children: [
      {
        element: <CommonLayout />,
        children: [
          {
            path: '/',
            loader: redirectToBoard,
          },
          {
            path: '/kanban-board',
            element: <KanbanBoard />,
          },
          {
            path: '/tickets/:id',
            element: <Tickets />,
          },
          {
            path: '/user-management',
            element: <UserManagement />,
          },
          {
            path: '/client-management',
            element: <ClientManagement />,
          },
          {
            path: '/dashboard',
            element: <Dashboard />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]
