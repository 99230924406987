import { preloadModule } from "@/preload-map"
import { useCallback } from "react";
import { NavLink, NavLinkProps } from "react-router-dom"

interface LinkProps extends NavLinkProps {
    prefetch?: boolean
}

export const Link = ({ to, children, prefetch, ...rest }: LinkProps) => {
    const handleMouseMove = useCallback(() => {
        if (prefetch && typeof to === 'string') {
            preloadModule(to);
        }
    }, [prefetch, to]);

    return (
        <NavLink
            to={to}
            onMouseMove={handleMouseMove}
            {...rest}
        >
            {children}
        </NavLink>
    )
}