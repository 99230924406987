import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import type { FC, ReactNode } from 'react'

import { useMutation } from '@tanstack/react-query'

import { LoadingSpinner } from '@/components/LoadingSpinner/LoadingSpinner'
import { Auth, SessionAuth, Token, auth } from '@/lib/auth'
import { REFRESH_TOKEN_KEY } from '@/lib/react-query/keys'

import { refreshToken } from './refresh-token-service'

export interface AuthContextType extends Auth {}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isCheckedCookie, setIsCheckedCookie] = useState(false)
  const oldSessionValue = useRef<SessionAuth | null>(null)
  const { isPending, mutate } = useMutation<Token | null, Error, string>({
    mutationKey: [REFRESH_TOKEN_KEY],
    mutationFn: (refresh_token: string) => {
      return refreshToken(refresh_token)
    },
  })

  useEffect(() => {
    const sessionCookie = auth.getSessionCookie()
    if (sessionCookie) {
      oldSessionValue.current = sessionCookie as SessionAuth
      const validToken = auth.verifyTokenId(sessionCookie.refresh_token_expired_at)

      if (validToken) {
        mutate(sessionCookie.refresh_token, {
          onSuccess: (accessToken) => {
            auth.setSession(Object.assign(sessionCookie, accessToken) as SessionAuth)
          },
          onError: () => {},
        })
      }
    }
    setIsCheckedCookie(true)
  }, [mutate])

  const contextValue = useMemo(() => auth, [])

  return (
    <AuthContext.Provider value={contextValue}>
      {!isCheckedCookie || isPending ? <LoadingSpinner /> : children}
    </AuthContext.Provider>
  )
}
