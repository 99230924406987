import { useQuery } from '@tanstack/react-query'

import { get } from '@/lib/api'
import { LIST_CLIENT_KEY } from '@/lib/react-query'
import { Client } from '@/types'

export function useListClient() {
  const { isLoading, error, data } = useQuery<Client[]>({
    queryKey: [LIST_CLIENT_KEY],
    queryFn: () => get('v1/clients'),
  })

  return { isLoading, error, data }
}
