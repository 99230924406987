type PreloadModule = {
  isLoading: boolean
  isLoaded: boolean
  load: () => Promise<any>
}

export function matchRoute(pattern: string, path: string): boolean {
  const patternParts = pattern.split('/')
  const pathParts = path.split('/')

  if (patternParts.length !== pathParts.length) {
    return false
  }

  return patternParts.every((part, index) => {
    if (part.startsWith(':')) {
      return true // This is a parameter, so it matches anything
    }
    return part === pathParts[index]
  })
}

export const PreloadMap: Record<string, PreloadModule> = {
  '/kanban-board': {
    isLoading: false,
    isLoaded: false,
    load: () => import('@/modules/kanban-board'),
  },
  '/tickets/:id': {
    isLoading: false,
    isLoaded: false,
    load: () => import('@/modules/tickets'),
  },
  '/user-management': {
    isLoading: false,
    isLoaded: false,
    load: () => import('@/modules/user-management'),
  },
  '/client-management': {
    isLoading: false,
    isLoaded: false,
    load: () => import('@/modules/client-management'),
  },
}

export const preloadModule = (path: string): void => {
  const matchingRoute = Object.keys(PreloadMap).find((route) => matchRoute(route, path))

  if (matchingRoute) {
    const module = PreloadMap[matchingRoute]
    if (!module.isLoaded && !module.isLoading) {
      module.isLoading = true
      module
        .load()
        .then(() => {
          module.isLoaded = true
        })
        .finally(() => {
          module.isLoading = false
        })
    }
  }
}
